<!-- 通知详情显示弹窗模块 -->
<template>
  <Dialog
    class="informBoxDeta"
    ref="dialogd"
    :config="{
      title: $fanyi('通知详情'),
      cancelBtn: proxy.$fanyi('关闭'),
    }"
  >
    <div class="InformBounced ql-editor">
      <h1>{{ readItem.title }}</h1>
      <p class="timeBox">{{ readItem.created_at }}</p>
      <div class="con" v-html="readItem.content"></div>
    </div>
  </Dialog>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import Dialog from "@/components/Dialog";
const { proxy } = getCurrentInstance();

//------------------------ data -------------------------------------
// 显示数据参数
const readItem = ref({});
//------------------------ pageLoad -------------------------------------

//------------------------ methods -------------------------------------
// 打开弹窗事件 (供父组件使用)
const open = (data) => {
  readItem.value = data;
  //   将通知标记为已读
  //   proxy.$api.noticeReadNotice({ id: data.id }).then((res) => {});
  proxy.$refs.dialogd.open();
};
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
.InformBounced {
  background: #fafafa;
  margin: 0 auto;
  border-radius: 10px;
  // width: 1120px;
  min-height: 600px;
  padding: 20px 40px 40px;
  background-color: #fafafa;
  overflow: visible;
  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 10px;
    // text-align: center;
  }
  .timeBox {
    font-size: 24px;
    height: 36px;
    // text-align: center;
    margin-bottom: 20px;
    color: #999999;
  }
  .con {
    max-height: 40vh;
    overflow: auto;
    line-height: 42px;
    font-size: 28px;
  }
}
</style>
<style lang="scss">
.informBoxDeta {
  .closeBtn {
    font-size: 28px;
  }
}
</style>
