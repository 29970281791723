<!-- 通知列表页面 -->
<template>
  <div class="informPage">
    <headBackBox iCustomizdde="true">
      <template #default>
        <span> {{ $fanyi("通知") }} </span>
        <div class="siftBox">
          <div
            class="siftOpt"
            @click="changeSift(1)"
            :class="{ active: hasRead == 1 }"
          >
            <img
              :src="require('@/assets/icon/tongZhiShaiXuan.png')"
              alt=""
              v-if="hasRead != 1"
            />
            <img
              :src="require('@/assets/icon/tongZhiShaiXuanHong.png')"
              alt=""
              v-else
            />
            <span>{{ $fanyi("未读") }}</span>
          </div>
          <div
            class="siftOpt"
            @click="changeSift(2)"
            :class="{ active: hasRead == 2 }"
          >
            <img
              :src="require('@/assets/icon/tongZhiShaiXuan.png')"
              alt=""
              v-if="hasRead != 2"
            />
            <img
              :src="require('@/assets/icon/tongZhiShaiXuanHong.png')"
              alt=""
              v-else
            />
            <span>{{ $fanyi("已读") }}</span>
          </div>
        </div>
      </template>
    </headBackBox>
    <ul class="notificationsBox">
      <li
        v-for="(NotifiItem, NotifiIndex) in listOfNotifications"
        :key="NotifiIndex"
        @click="openDetailDia(NotifiItem)"
      >
        <input
          type="checkbox"
          :checked="NotifiItem.checked"
          @click.stop="
            NotifiItem.checked = !NotifiItem.checked;
            checkOne(NotifiIndex);
          "
        />
        <div class="iconBox">
          <img :src="require('@/assets/icon/lingdang.png')" alt="" />
          <div class="newNotific" v-if="NotifiItem.statusName == '未读'"></div>
        </div>
        <span :class="{ grayFont: NotifiItem.statusName != '未读' }">{{
          NotifiItem.title
        }}</span>
        <van-icon name="arrow" />
      </li>
    </ul>
    <DialogVue ref="DialogVueRef" />
    <informDetailDialogVue ref="informDetailDialogVueRef" />
    <div class="footBarBox">
      <input type="checkbox" :checked="checked" @click="allChoice()" />
      <span @click="allChoice()">{{ $fanyi("全选") }}</span>
      <button @click="deleteData">{{ $fanyi("删除") }}</button>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import headBackBox from "@/components/headGoBack";
import DialogVue from "@/components/Dialog";
import informDetailDialogVue from "./informDetailDialog.vue";
const { proxy } = getCurrentInstance();
// ------------------------------ data ------------------------------------------------------
// 是否已读
const hasRead = ref(null);
// 通知列表数据
const listOfNotifications = ref([]);
// 是否全选
const checked = ref(false);
const commodityTotalData = ref({ ids: [], unread: false });
// ------------------------------ pageLoad ------------------------------------------------------
onMounted(() => {
  getData();
});
// ------------------------------ methods ------------------------------------------------------
// 打开详情弹窗
const openDetailDia = (data) => {
  proxy.$refs.informDetailDialogVueRef.open(data);
  // 对通知标记用户已读
  proxy.$api.noticeReadNotice({ id: data.id }).then((res) => {
    if (res.code != 0) return;
    data.statusName = "已读";
  });
};
// 获取数据
const getData = () => {
  proxy.$api
    .noticeClientNoticeList({
      hasRead: hasRead.value,
    })
    .then((res) => {
      ////console.log('事件名',res)
      if (res.code != 0) return;
      listOfNotifications.value = res.data.data;
      //接下来的操作
    });
};
// 修改筛选条件
const changeSift = (val) => {
  if (hasRead.value == val) {
    hasRead.value = null;
  } else {
    hasRead.value = val;
  }
  getData();
};
// 是否全选中
const isCheckedAll = () => {
  var flag = listOfNotifications.value.every(
    (item, index) => item.checked == true
  );
  if (flag == true) {
    checked.value = true;
  } else {
    checked.value = false;
  }
};
// 单选
const checkOne = (i) => {
  isCheckedAll();
  commodityTotal();
};
// 统计选中的各种数据
const commodityTotal = () => {
  // 初始化数据
  commodityTotalData.value = { ids: [], unread: false };
  listOfNotifications.value.forEach((item, index) => {
    if (item.checked) {
      commodityTotalData.value.ids.push(item.id);
      if (item.statusName == "未读") {
        commodityTotalData.value.unread = true;
      }
    }
  });
};
// 通知列表全选
const allChoice = () => {
  checked.value = !checked.value;
  listOfNotifications.value.forEach((item, index) => {
    item.checked = checked.value;
  });

  commodityTotal();
};
// 删除数据
const deleteData = () => {
  let diaText = proxy.$fanyi("是否确认删除选中的内容？");

  if (commodityTotalData.value.unread) {
    diaText = proxy.$fanyi(
      "当前选中的通知中，包含有未读的通知，知否确认删除选中的内容？"
    );
  }

  if (commodityTotalData.value.ids.length <= 0) {
    return proxy.$message.warning(proxy.$fanyi("请至少选择一项通知"));
  }
  proxy.$refs.DialogVueRef.open({
    text: diaText,
    successMethods: () => {},
    successBtn: proxy.$fanyi("确认"),
    cancelBtn: proxy.$fanyi("取消"),
    isprompt: true,
    successMethods: () => {
      // return console.log(commodityTotalData.value.ids);
      proxy.$api
        .noticeBatchDelete({ ids: commodityTotalData.value.ids })
        .then((res) => {
          if (res.code != 0)
            return proxy.$message.error(proxy.$fanyi("操作失败"));
          proxy.$message.success(proxy.$fanyi("操作成功"));
          getData();
        });
    },
  });
  //   proxy.$refs.DialogVueRef.open();
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.informPage {
  padding-bottom: 100px;
  min-height: 100vh;
  background-color: #f6f6f6;
}
.siftBox {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 36px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  .siftOpt {
    margin-left: 40px;
    img {
      width: 20px;
      height: 18px;
    }
    span {
      font-size: 24px !important;
      font-weight: 400 !important;
    }
  }
  .siftOpt.active {
    span {
      color: #b4272b;
    }
  }
}
input[type="checkbox"] {
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  margin: 0;
  margin-top: 4px;
  border-color: #dfdfdf;
}
.notificationsBox {
  li {
    padding: 30px;
    display: flex;
    align-items: center;
    input[type="checkbox"] {
      margin-right: 20px;
    }
    .iconBox {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      background: linear-gradient(90deg, #ffac39 0%, #ffc33d 99%);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 20px;
      img {
        width: 22px;
        height: 24px;
      }
      .newNotific {
        position: absolute;
        right: 0;
        top: 0;
        width: 12px;
        height: 12px;
        background: #b4272b;
        border-radius: 50%;
      }
    }
    .yiDU {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
    span {
      line-height: 42px;
      font-size: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span.grayFont {
      color: #999999;
    }
    :deep(.van-icon-arrow) {
      margin-left: auto;
      font-size: 25px;
    }
  }
}
.footBarBox {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  bottom: 0px;
  padding: 15px 30px;

  span {
    margin-left: 7px;
    font-size: 24px;
  }
  button {
    width: 120px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    font-size: 28px;
    color: white;
    margin-left: auto;
  }
}
</style>
